(function () {
  "use strict";

  var suffix = window.location.origin.split(".");
  suffix = suffix[suffix.length-1];

  angular.module("EntrakV5").constant("REDIRECT_PORTAL_URL", "https://dev.tenant.en-trak.com");

  angular.module("EntrakV5").constant("REDIRECT_DEMO_URL", "https://demo.en-trak." + suffix + "/#!/demo");

  angular.module("EntrakV5").constant("REDIRECT_LANDLORD_URL", "https://dev.landlord.en-trak." + suffix + "/#!/landing");

  angular.module("EntrakV5").constant("CLIENT_ID", "bd971ab3-6cf0-4a1d-9ba2-b815fcd3ae2b");

  angular.module("EntrakV5").constant("MS_ENDPOINT", "https://login.microsoftonline.com/common/");

  angular.module("EntrakV5").constant("COOKIE_PREFIX", "dev_");

  angular.module("EntrakV5").constant("IS_LOCAL", "");
  
  // for Cognito
  // angular.module("EntrakV5").constant("CLIENT_ID", "6a118tm6coc3otf57ecsera0hi");
  // angular.module("EntrakV5").constant("MS_OAUTH_URL", "https://sbos-oauth.auth.ap-southeast-1.amazoncognito.com/oauth2/authorize?identity_provider=Microsoft&response_type=CODE&scope=email%20openid%20phone%20profile&redirect_uri=https%3A%2F%2Fdev.api.en-trak.com%2Fauth%2Fcallback");

  angular.module("EntrakV5").constant("URL", "https://dev.api.en-trak.com/");

  // angular.module("EntrakV5").constant("CLIENT_SECRET", "jKNaF-~fpe42uy4GJC~1eQo~JB1_s-1KCt");

})();
